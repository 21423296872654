.grid-container {
    display:inline-grid;
    gap: 50px;
  }

  .item1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }
  .center-screen-noresize{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
/* 
  body {
    background: #1C8EF9 !important;
    min-height: 100vh;
    display: flex;
    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
  } */

  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }
  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  .custom-control-label {
    font-weight: 400;
  }
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  .forgot-password a {
    color: #167bff;
  }

  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 12px;
    background-color: #ffffff0a;
    width: "40vh";
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
    color: #ffffffdd;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: #ffffffdd;
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: #ffffffdd;
    color: rgb(34, 34, 34);
  }

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #aaa;
}